<template>
    <div class="form-elements">
        <vuestic-widget :headerText="$t('view.device.title')">
            <div class="row">
                <div class="col-md-3">
                    <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.device.back' | translate}}</button>
                </div>
                <div class="col-md-3 offset-md-6">
                    <!-- <button type="button" class="btn btn-warning btn-sm">Delete Element</button> -->
                </div>
            
            </div>
        
        </vuestic-widget>
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget :headerText="'view.device.new' | translate">
                    <form @submit.prevent="onSubmit">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessNameValid}">
                                                <div class="input-group">
                                                    <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="name">{{'view.device.fields.name' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('mac'), 'valid':isSuccessMacValid}">
                                                <div class="input-group">
                                                    <input id="mac" name="mac" v-model="mac" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="mac">{{'view.device.fields.mac' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('mac')" class="help text-danger">
                                                        {{ errors.first('mac') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('serial'), 'valid':isSuccessSerialValid}">
                                                <div class="input-group">
                                                    <input id="serial" name="serial" v-model="serial" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="serial">{{'view.device.fields.serial' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('serial')" class="help text-danger">
                                                        {{ errors.first('serial') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('model'), 'valid':isSuccessModelValid}">
                                                <div class="input-group">
                                                    <input id="model" name="model" v-model="model" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="serial">{{'view.device.fields.model' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('model')" class="help text-danger">
                                                        {{ errors.first('model') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('softwareversion'), 'valid':isSuccessSoftWareValid}">
                                                <div class="input-group">
                                                    <input id="softwareversion" name="softwareversion" v-model="softwareversion" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="softwareversion">{{'view.device.fields.softwareversion' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('softwareversion')" class="help text-danger">
                                                        {{ errors.first('softwareversion') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right">
                                                <div class="input-group">
                                                    <vuestic-switch v-model="active">
                                                        <span slot="trueTitle">{{$t('view.device.activated') | translate}}</span>
                                                        <span slot="falseTitle">{{$t('view.device.disabled') | translate}}</span>
                                                    </vuestic-switch>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </fieldset>
                                <div class="col-md-3 offset-md-12">
                                    <button class="btn btn-primary btn-sm" type="submit">{{$t('view.device.save')}}</button>
                                </div>
                            </div>
                        </div>
                    
                    </form>
                </vuestic-widget>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name:     'NewDevice',
        data () {
            return {
                name:            '',
                mac:             '',
                serial:          '',
                model:           '',
                softwareversion: '',
                active:          1,
            }
        },
        mounted () {

        },
        methods:  {
            ...mapActions([
                'storeDevice',
                'addToastMessage',
            ]),
            onSubmit () {
                this.$validator.validate().then(result => {
                    if (result) {
                        this.storeDevice({name: this.name, mac: this.mac, serial: this.serial, model: this.model, softwareversion: this.softwareversion, active: this.active})
                            .then((data) => {
                                this.addToastMessage({
                                    text: data.message,
                                    type: 'success'
                                })
                                this.$router.replace('/devices')
                            })
                            .catch((data) => {
                                this.error = data.message
                            })
                    }
                })
            },
            clear (field) {
                this[field] = ''
            },
            back () {
                this.$router.go(-1)
                // this.$router.push('/')
            },
        },
        computed: {
            isSuccessNameValid () {
                let isValid = false
                if (this.formFields.name) {
                    isValid = this.formFields.name.validated && this.formFields.name.valid
                }
                return isValid
            },
            isSuccessMacValid () {
                let isValid = false
                if (this.formFields.mac) {
                    isValid = this.formFields.mac.validated && this.formFields.mac.valid
                }
                return isValid
            },
            isSuccessSerialValid () {
                let isValid = false
                if (this.formFields.serial) {
                    isValid = this.formFields.serial.validated && this.formFields.serial.valid
                }
                return isValid
            },
            isSuccessModelValid () {
                let isValid = false
                if (this.formFields.model) {
                    isValid = this.formFields.model.validated && this.formFields.model.valid
                }
                return isValid
            },
            isSuccessSoftWareValid () {
                let isValid = false
                if (this.formFields.softwareversion) {
                    isValid = this.formFields.softwareversion.validated && this.formFields.softwareversion.valid
                }
                return isValid
            },
        },
    }
</script>
